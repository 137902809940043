<template>
	<div class="row-space-tbf" v-if="loaded">
		<div class="space-left"><div v-if="$resize && $mq.above(780)" class="go-back" @click="backRoute(selectedUser.slug)"><icon-arrow-back /></div></div>
		<div class="content opacity-page">
			<div class="header-form">
				<h1 class="title">{{ $t('user-details.title-warning') }}</h1>
				<div class="actions">
					<button class="btn-tbf white only-icon" @click="backRoute(selectedUser.slug)"><icon-close class="icon-close" /></button>
				</div>
			</div>

			<div class="form-group">
				<div class="data-group">
					<div class="title">{{ $t('user-details.title-warning') }}</div>
					<div class="description">{{ $t('user-details.description-warning') }}</div>
				</div>
				<div class="form">
					<div class="input-group" :class="$resize && $mq.above(600) ? 'w-50' : 'w-100'">
						<div class="label-header">
							<label class="label">{{$t('user-details.users')}}</label>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.selectedUser.$error}">
							<div class="icon-left"><icon-people /></div>
							<multiselect 
							v-model="selectedUser"
							:options="users"
							:allow-empty="false"
							:show-labels="false"
							:multiple="false" 
							:close-on-select="true"
							track-by="id"
							label="name"
							@input="getResponsabilities"
							>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{$t('user-details.choose_user')}}
									</span>
								</template>
								<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
								<template slot="option" slot-scope="props">{{ props.option.name }}</template>
								<template slot="noResult">{{ $t('general.no_result') }}</template>
								<template slot="noOptions">{{ $t('general.empty_list') }}</template>
							</multiselect>
						</div>
					</div>
                    <div class="input-group" :class="$resize && $mq.above(600) ? 'w-50' : 'w-100'">
						<div class="label-header">
							<label class="label">{{$t('user-details.responsibilities')}}*</label>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.selectedResponsibility.$error}">
							<div class="icon-left"><icon-list /></div>
							<multiselect 
							v-model="selectedResponsibility"
							:options="responsibilities"
							:allow-empty="false"
							:show-labels="false"
							:multiple="false" 
							:close-on-select="true"
							track-by="id"
							label="name"
							group-values="responsibilities"
							group-label="role"
							:group-select="false"
							:disabled="!selectedUser"
							>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{$t('user-details.choose_responsibility')}}
									</span>
								</template>
								<template slot="noResult">{{ $t('general.no_result') }}</template>
								<template slot="noOptions">{{ $t('general.empty_list') }}</template>
							</multiselect>
						</div>
					</div>
                    <div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('user-details.description') }}*</label>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.description.$error}">
                            <textarea v-model="description" class="input-text" rows="10"></textarea>
						</div>
					</div>
				</div>
			</div>
			
			<div class="form-submit crud">
				<div class="error-msg" v-show="error_message != ''">{{error_message}}</div>
				<button class="btn-tbf grey" @click="backRoute(selectedUser.slug)"><span class="text">{{ $t('general.cancel') }}</span></button>
				<button id="buttonCreate" class="btn-tbf blue" @click="saveAction()">
					<div class="loader"></div>
					<span class="text">{{ $t('general.add') }}</span>
				</button>
			</div>
		</div>
		<div class="space-right"></div>
	</div>
	<loader-users-create  v-else/>
</template>

<script>
	import IconArrowBack from '../../Icons/ArrowLong'
	import IconClose from '../../Icons/Close'
    import IconPeople from '../../Icons/User'
	import IconList from '../../Icons/Responsibility'
	import LoaderUsersCreate from '../../PagesLoaders/UsersCreate'
	import { required, requiredIf } from 'vuelidate/lib/validators'

	export default {
		components: {
			IconArrowBack,
			IconClose,
            IconPeople,
			IconList,
			LoaderUsersCreate
		},
		data() {
			return {
				loaded: false,
				description: '',
                error_message: '',
                users: [],
				selectedUser: '',
                responsibilities: [],
				selectedResponsibility: '',
			};
		},
		async mounted(){
			if(this.$route.query.userId){
            	await this.getData(true);
			}else{
            	await this.getData(false);
			}
		},
		validations: {
			description: {required},
            selectedUser: {required},
            selectedResponsibility: {required}
		},
		methods: {
           	async getData(withResponsabilities) {
           		let paramsRequest = { users: true }
            	if(withResponsabilities){
            		paramsRequest.user_responsibilities = true
            		paramsRequest.userId = this.selectedUser ? this.selectedUser.id : this.$route.query.userId
            	}
            	
                await axios.get(`instances/${this.$auth.user().instance.id}/filter`, { params: paramsRequest})
        		.then(({data}) => {
        			this.users = data.data.users
                    if(withResponsabilities){
        				this.responsibilities = data.data.responsibilities
						this.selectedUser = this.users.find(el => el.id == this.$route.query.userId)
					}
        		}).catch(error => {
        			if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
        		})
        		.finally(() => {
        			setTimeout(() => {
        				this.loaded = true
        				setTimeout(() => {
        					$('.opacity-page').addClass('show')
        				}, 0)
        			}, 0)
        		})
            },
			backRoute(slug) {
				if(slug) {
					this.$router.push({ name: 'user-show', params: {slug: slug}, query: { from: 'warnings'} })
				} else {
					this.$router.push({name: 'users'})
				}
			},
			saveAction(){
				var buttonName = `buttonCreate`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';

				this.$v.$touch()
				if(!this.$v.$invalid){
					let objData = {}
					objData.user_id = this.selectedUser.id
                    objData.body = this.description
                    objData.role_responsibility_id = this.selectedResponsibility.id
                    objData.type = 'warnings'
					this.submitStore(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML =this.$t('general.add')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
            submitStore(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/reviews/store', objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.$v.$reset()
						setTimeout(()=>{
							this.backRoute(this.selectedUser.slug)
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.add')
						}, 1000)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
			},
			getResponsabilities(value){
				axios.get(`instances/${this.$auth.user().instance.id}/filter`, { params: {user_responsibilities: true, userId: value.id} })
        		.then(({data}) => {
        			this.responsibilities = data.data.responsibilities
        		})
			}
		}
	};
</script>